import {SmartComponent} from "smart-component-js";
import Swiper, { Navigation, Pagination } from "swiper";


const sliderConfiguration = {
    classic: {
        slidesPerView: 1,
        spaceBetween: 20
    },
    products: {
        slidesPerView: 1.3,
        autoplay: false,
        spaceBetween: 20
    },
    carousel: {
        slidesPerView: 1.35,
        spaceBetween: 20,
        breakpoints: {
            768: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 16,
            }
        },
    },
    default: {
        slidesPerView: 1,
        spaceBetween: 0
    }
};

class GenericSliderComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.pagination = params?.pagination || $(element).find('.swiper-pagination')[0];
        this.prev = params?.prev || $(element).find('.swiper-button-prev:visible')[0];
        this.next = params?.next || $(element).find('.swiper-button-next:visible')[0];
        this.config = sliderConfiguration[this.element.dataset.sliderConfig || 'default'];
        this.init();
    }

    initSwiper() {
        this.swiper = new Swiper(this.element, {
            ...this.config,
            modules: [ Navigation, Pagination ],
            pagination: {
                el: this.pagination,
                clickable: true,
                type: 'bullets'
            },
            navigation: {
                nextEl: this.next,
                prevEl: this.prev,
            },
        });

        document.addEventListener('reinit', ()=> {
            this.swiper.update();
        });
    }

    productsConfig() {
        if (this.element.dataset.sliderConfig == 'products') {
            $(this.element).find('.yxpRow').addClass('swiper-wrapper');
            $(this.element).find('.swiper-wrapper .yxpBlock').addClass('swiper-slide');
            $(this.element).append(`
                <div class="swiper-nav-pag-cnt">
                    <div class="swiper-pagination"></div>
                    <div class="swiper-navigation">
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            `);

            this.pagination = $(this.element).find('.swiper-pagination')[0];
            this.prev = $(this.element).find('.swiper-button-prev:visible')[0];
            this.next = $(this.element).find('.swiper-button-next:visible')[0];
        }
    }

    init() {
        if (this.element.dataset.sliderConfig != 'products') {
            this.initSwiper();
        } else {
            if (window.innerWidth < 768) {
                this.productsConfig();
                this.initSwiper();
                this.loadChildComponents(this);
            }
        }
    }
}

export default GenericSliderComponent;
