import {SmartComponent} from "smart-component-js";

class CustomFormComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }


    init() {
        document.addEventListener("yxp-form-loaded", () => {
            this.$el.find('.yxpSubmitBtn').text(KD_COMMON_TRANSLATIONS["form.submit"]);
            this.$el.find('[name="locale"]').val(KD_COMMON_CONSTANTS.LOCALE);
            this.$el.find('.replace-wrapper').each((i, el) => {
                let text = $(el).text();
                let contains = false;
                while (text.indexOf('{{') >= 0 && text.indexOf('}}') >= 0) {
                    contains = true;
                    let split = [text.substr(0, text.indexOf('{{')), text.substr(text.indexOf('{{') + 2)];
                    split[2] = split[1].substr(split[1].indexOf('}}') + 2);
                    split[1] = split[1].substr(0, split[1].indexOf('}}'));

                    if (typeof KD_COMMON_TRANSLATIONS[split[1]] !== 'undefined') {
                        split[1] = KD_COMMON_TRANSLATIONS[split[1]];
                    }

                    text = split.join('');
                }

                if (contains) {
                    $(el).text(text);
                }
            });

            this.$el.find('[type="file"]').change((e) => {
                $(e.currentTarget).parents('.yxpInputGroup').toggleClass('has-value', $(e.currentTarget).val().length > 0);
            });

            this.$el.on('click', '.yxpType_UPLOAD_INPUT .erase', (e) => {
                e.preventDefault();
                e.stopPropagation();

                $(e.currentTarget).parents('.yxpInputGroup').find('[type="file"]').val(null).change();
            })

            this.$el.find('.yxpType_DOCUMENT').each((i, el) => {
                let label = $(el).attr('label');
                if (label && label.length > 1) {
                    $(el).find('a').text(label);
                }
            });
            this.$el.find('.yxpType_UPLOAD_INPUT .yxpInputGroup ').append('<span class="erase"></span>');
        });
        document.addEventListener("yxp-form-submit-ok", () => {
            this.$el.find('.formResponseOK').text(KD_COMMON_TRANSLATIONS["form.success"]);
        });
    }

}

export default CustomFormComponent;
