import {SmartComponent} from "smart-component-js";
import HttpClient from "../../business/HttpClient";

class ContactComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }

    getUserInfo() {
        let params = {
            callback: this.fillUserInfo
        };
        gigya.accounts.getAccountInfo(params);
    }

    fillUserInfo(data) {
        $('[name="name"]').val(data.profile.firstName).attr('readonly', true);
        $('[name="surname"]').val(data.profile.firstName).attr('readonly', true);
        $('[name="customer"]').val(data.data.ERPCustomerCode).attr('readonly', true);
        $('[name="email"]').val(data.profile.email).attr('readonly', true);

        $('[name="country"]').val(data.profile.country).attr('readonly', true);

        $('.registration-form [type="submit"]').removeAttr('disabled', 'disabled');
    }

    init() {
        this.getUserInfo();
        this.$el.find('form').submit((e) => {
            e.preventDefault();

            this.$el.find('[type="submit"]').attr('disabled', 'disabled');

            grecaptcha.ready(() => {
                grecaptcha.execute(KD_COMMON_CONSTANTS.GOOGLE_RECAPTCHA_SECRET_CLIENT, {action: 'submit'}).then((token) => {
                    this.$el.find('[name="recaptchaToken"]').val(token);

                    let data = this.$el.find('form').serializeArray().reduce((m, i) => {
                        m[i.name] = i.value;
                        return m;
                    }, {})

                    HttpClient.doCall({
                        url: '/api/1.0/trelleborg/form/contact',
                        contentType: 'application/json',
                        method: 'POST',
                        data: JSON.stringify(data)
                    }).then(() => {
                        this.$el.find('.registration-form,.registration-intro').hide();
                        this.$el.find('.registration-completed').show();
                    }, () => {
                        this.$el.find('.registration-form,.registration-intro').hide();
                        this.$el.find('.registration-error').show();
                    });
                });
            });

        });

        this.$el.find('[type="submit"]').on('mousedown', () => {
            this.$el.find('form').addClass('touched');
        })

        this.loadChildComponents(this);
    }
}

export default ContactComponent;
