var Utility = {
    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    scrollTo(elem) {
        $('html, body').animate({scrollTop: elem}, 400);
    },

    getCurrentUri() {
        return window.location.href.replace(window.location.origin, "");
    },

    getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            sParameterValue,
            index,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            index = sURLVariables[i].indexOf('=');
            sParameterName = sURLVariables[i].slice(0, index);
            sParameterValue = sURLVariables[i].slice(index + 1);

            if (sParameterName === sParam) {
                return sParameterValue === undefined ? true : sParameterValue;
            }
        }
    },

    preloadImage(src) {
        new Promise(r => {
            const image = new Image()
            image.onload = r
            image.onerror = r
            image.src = src
        })
    },

    removeLoading(element) {
        $(element).removeClass('loading');
    },

    formatDate(timestamp, format) {
        let date;
        if (!timestamp) {
            date = new Date();
        } else {
            date = new Date(timestamp);
        }
        if (!format || format == "yyyy-mm-dd") {
            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else if (format == "dd-mmm-yyyy") {
            let monthNames = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"];
            return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
        }
    },
    formatCompleteDate(date) {
        let d = new Date(date);
        let off = d.getTimezoneOffset() * 60000
        let newdt = new Date(d - off).toISOString()
        return newdt.replace('Z', '').replace('T', ' - ');
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatName(string) {
        var tmp = string.charAt(0).toUpperCase() + string.slice(1);
        tmp = tmp.replace(/[^a-zA-Z ]/g, "");
        return tmp;
    },
    twoDigits(num) {
        return num.length > 1 ? num : num + '0';
    },
    isMobile() {
        return $(window).width() < 768;
    },
    isNotValidText(string) {
        if (string !== undefined && string != null && string.length > 1) {
            var splitted = string.split(" ");
            for (var y = 0; y < splitted.length; y++) {
                for (var i = 0; i < this.forbiddenWords.length; i++) {
                    if (splitted[y].toLowerCase() == this.forbiddenWords[i]) {
                        return true;
                    }
                }
            }
        }
        return false;
    },
    filepath2Url(path) {
        let reg = /^(.*?)(\bwebapp\b)(.*)$/;
        let find = path.match(reg);
        if (find.length() == 3) {
            return BE_LAVAZZA_CONSTANTS.MEDIA_HOSTNAME + find[2];
        } else {
            return false;
        }
    },
    isFacebookBrowser() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        //alert(ua);
        return /FBAN|FBAV/i.test(ua);
    },

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email == "" || re.test(email);
    }
};

export default Utility;
