import {SmartComponent} from "smart-component-js";

class RegionAccordionComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.accordionsHeader = this.element.querySelectorAll('.splashPage__accordion-header');

        this.init();
    }

    calculateAccordionHeight() {
        const bodies = this.element.querySelectorAll('.splashPage__accordion-body');
        bodies.forEach((body) => body.style.height = body.scrollHeight + 'px');
    }

    addAccordionListener() {
        this.accordionsHeader.forEach((accordion) => {
            accordion.addEventListener('click', () => accordion.parentElement.classList.toggle('closed'));
        });
    }

    init() {
        this.calculateAccordionHeight();
        this.addAccordionListener();
    }
}

export default RegionAccordionComponent;
