import {SmartComponent} from "smart-component-js";
import Swiper, { Pagination, Navigation } from 'swiper';

class CardWideComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.swiper = null;
        this.wrapper = element;
        this.init();
    }

    initSwiper() {
        if (window.matchMedia('(max-width: 768px)')) {
            Swiper.use([Pagination, Navigation]);
            this.swiper = new Swiper(this.wrapper, {
                slidesPerView: 1.2,
                pagination: {
                    el: ".cardWide-wrapper .swiper-pagination",
                    type: "bullets",
                    clickable: true
                },
                navigation: {
                    nextEl: '.cardWide-wrapper .swiper-button-next',
                    prevEl: '.cardWide-wrapper .swiper-button-prev',
                },
            });
        }
    }

    init() {
        this.initSwiper();
        this.loadChildComponents(this);
    }
}

export default CardWideComponent;
