import {SmartComponent} from "smart-component-js";
import Swiper, {Pagination, Navigation, Autoplay} from 'swiper';

class VisualComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.swiper = null;
        this.wrapper = element;
        this.init();
    }

    initSwiper() {
        Swiper.use([Pagination, Autoplay]);
        this.swiper = new Swiper(this.wrapper, {
            slidesPerView: 1,
            autoplay: false,
            loop: true,
            pagination: {
                el: ".visual .swiper-pagination",
                type: "bullets",
                clickable: true
            },
        });
    }

    init() {
        this.initSwiper();
    }
}

export default VisualComponent;
