import {SmartComponent} from "smart-component-js";
import Utility from "../../business/Utility";
import HttpClient from "../../business/HttpClient";

class RegistrationComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }

    init() {
        // this.$el.find('input,textarea,select').change((e) => {
        //     this.validateField($(e.currentTarget).parents('.input-group'));
        // });

        this.$el.find('form').submit((e) => {
            e.preventDefault();

            this.$el.find('[type="submit"]').attr('disabled', 'disabled');

            grecaptcha.ready(() => {
                grecaptcha.execute(KD_COMMON_CONSTANTS.GOOGLE_RECAPTCHA_SECRET_CLIENT, {action: 'submit'}).then((token) => {
                    this.$el.find('[name="recaptchaToken"]').val(token);

                    let data = this.$el.find('form').serializeArray().reduce((m, i) => {
                        m[i.name] = i.value;
                        return m;
                    }, {})

                    HttpClient.doCall({
                        url: '/api/1.0/trelleborg/form/register',
                        contentType: 'application/json',
                        method: 'POST',
                        data: JSON.stringify(data)
                    }).then(() => {
                        this.$el.find('.registration-form,.registration-intro').hide();
                        this.$el.find('.registration-completed').show();
                    }, () => {
                        this.$el.find('.registration-form,.registration-intro').hide();
                        this.$el.find('.registration-error').show();
                    });

                });
            });

        });

        this.$el.find('[type="submit"]').on('mousedown', () => {
            this.$el.find('form').addClass('touched');
        })

        this.loadChildComponents(this);
    }

    validate() {
        let error = false;
        this.$el.find('.input-group[data-required="required"]').each((i, el) => {
            error = error || this.validateField(el);
        });

        if (error) {
            let top = $('.error:first').offset().top - 150;
            if (top < 0) {
                top = 0;
            }
            Utility.scrollTo(top);
        }

        return !error;
    }

    validateField(el) {
        let $field = $(el).find('input,textarea,select');
        let fieldError = false;
        if ($field.is('[type="email"]')) {
            fieldError = Utility.validateEmail($field.val());
        } else {
            fieldError = $field.val().length > 0;
        }

        $(el).toggleClass('error', fieldError);
        return !fieldError;
    }
}

export default RegistrationComponent;
