import $ from "jquery";
import {SmartComponentManager} from "smart-component-js";
import DefaultCtrl from "./controllers/DefaultCtrl";
import BannerFullWidthComponent from "./components/BannerFullWidthComponent";
import CardWideComponent from "./components/CardWideComponent";
import RegistrationComponent from "./components/RegistrationComponent";
import ContactComponent from "./components/ContactComponent";
import CustomFormComponent from "./components/CustomFormComponent";
import RegionAccordionComponent from "./components/RegionAccordionComponent";
import VisualComponent from "./components/VisualComponent";
import GenericSliderComponent from "./components/GenericSliderComponent";


import "../../styles/trelleborg/trelleborg.scss";


SmartComponentManager.registerComponents({
    DefaultCtrl,
    BannerFullWidthComponent,
    CardWideComponent,
    RegistrationComponent,
    ContactComponent,
    CustomFormComponent,
    RegionAccordionComponent,
    VisualComponent,
    GenericSliderComponent
});

$(document).ready(() => {
    let $body = $('body[component], .bodySurrogate[component]');
    if ($body.length > 0) {
        SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
    } else {

    }
});

